<template>
  <div class="fullScreen" :class="{ show: showEditDialog }" v-loading="loading">
    <Crumbs>
      <!-- <el-breadcrumb-item slot="after"> 审核商务信息 </el-breadcrumb-item> -->
    </Crumbs>
    <div class="fullScreenMain" v-loading="loading">
      <el-tabs v-model="activeName" class="fullScreenMainHeader">
        <el-tab-pane label="基本信息"></el-tab-pane>
        <el-tab-pane label="附件"></el-tab-pane>
        <el-tab-pane name="2" label="商务团队分工" v-if="form.id"></el-tab-pane>
        <el-tab-pane name="3" label="商务追踪" v-if="form.id"></el-tab-pane>
        <el-tab-pane name="4" label="商务绩效" v-if="form.id"></el-tab-pane>
      </el-tabs>

      <div class="fullScreenMainCon">
        <Manage
          width="600px"
          ref="Manage"
          :options="optionInfo"
          :isBusinessLog="true"
          @showEditDialog="showEditDialog = true"
          :BusinessId="form.id"
          v-show="activeName == 0"
        ></Manage>
        <Annex
          :manageId="form.id"
          :disabled="form.list_s"
          :file_Data="file_Data"
          @changed="FileChangeFn"
          v-show="activeName == 1"
        ></Annex>
        <Assign width="600px" :ManageId="form.id" v-show="activeName == 2"></Assign>
        <Business width="600px" :BusinessId="form.id" v-show="activeName == 3"></Business>
        <DistriBution
          width="600px"
          :Disabled="true"
          :options="{ businessManageId: form.id }"
          v-if="activeName == 4"
        ></DistriBution>
      </div>
      <div class="fullScreenOperation">
        <el-button v-if="!form.list_s" type="success" :loading="loading" @click="Save()">
          移交计划经营部
        </el-button>
        <el-button v-if="!form.list_s" type="warning" :loading="loading" @click="suspendFn">
          商务暂停
        </el-button>
        <el-button v-if="!form.list_s" type="danger" :loading="loading" @click="finalityFn">
          商务终结
        </el-button>
        <el-button type="info" @click="re">返回</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { delcommafy } from '@/util/jsencrypt'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Annex: () => import('./Annex.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    Assign: () => import('@/views/publicView/Assign.vue'),
    Business: () => import('@/views/publicView/Business.vue'),
    Manage: () => import('@/views/publicView/Manage.vue'),
    DistriBution: () => import('@/views/publicView/DistriBution.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      showEditDialog: false,
      loading: false,
      submitLoading: false,
      form: {},
      activeName: '0',
      rules: {},
      deptList: [], // 部门列表
      postList: [], //岗位列表
      type: 'portrait',
      file_Data: [],
      project_source: [
        '由公司商务团队或员工直接获取的项目',
        '以外部合作者为主（居间、咨询）获取的项目',
        '第三方公司挂靠慧图的项目',
      ],
      project_source_detail: [
        '直接以公司名义（含子公司）承接实施的一手项目',
        '以公司推荐的其他公司名义承接由公司实施的项目',
      ],
      businessFileList: [],
      oldBusinessFileList: [],
      optionInfo: {
        list_s: 0,
      },
    }
  },
  computed: {},
  inject: ['re'],
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        // this.showEditDialog = newVal;
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        this.init()
      },
    },
  },
  methods: {
    FileChangeFn(arr) {
      this.businessFileList = arr
    },
    finalityFn() {
      this.$confirm('确定终结该商务？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.loading = true

          this.$api.businessManage
            .finality(this.form.id)
            .then(res => {
              this.loading = false
              this.showEditDialog = false
              this.$message.success('终结成功！')
              this.re()
            })
            .catch(err => {
              this.loading = false
              console.log(err)
            })
        })
        .catch(() => {})
    },
    suspendFn() {
      this.$confirm('确定暂停该商务？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.loading = true

          this.$api.businessManage
            .pause(this.form.id)
            .then(res => {
              this.loading = false
              this.showEditDialog = false
              this.$message.success('暂停成功！')
              this.re()
            })
            .catch(err => {
              this.loading = false
              console.log(err)
            })
        })
        .catch(() => {})
    },
    Save() {
      this.$refs.Manage.businessCheck()
      let isCcheck = this.$refs.Manage?.isCcheck
      if (isCcheck) {
        this.$confirm(
          '确认' + this.form.role_type == 'fz' ? '批准该项目立项？' : '申请立项？',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
          .then(() => {
            //     this.form.status = 5
            //     let obj = this.form
            //     this.loading = true

            //     this.$api.businessManage
            //       .updateBusinessManageStatus(obj)
            //       .then(res => {
            //         this.loading = false
            //         this.showEditDialog = false
            //         this.$message.success('移交成功！')
            //         this.re()
            //       })
            //       .catch(err => {
            //         this.loading = false
            //         console.log(err)
            //       })

            let obj = {}
            obj.businessManage = this.$refs.Manage.form
            obj.status = 5
            obj.bidId = obj.businessManage.bidId || ''
            obj.businessFileList = this.businessFileList
            let fileListId = this.businessFileList.map(item => item.id)
            obj.businessFileDeleteList = this.oldBusinessFileList.filter(
              item => !fileListId.includes(item)
            )
            obj.businessManage.predictMoney = Number(delcommafy(obj.businessManage.predictMoney))
            // obj.businessManage.tenderFee = Number(delcommafy(obj.businessManage.tenderFee))
            obj.businessManage.contentCooperationMoney = Number(
              delcommafy(obj.businessManage.contentCooperationMoney)
            )

            obj.businessManage.tenderFee = Number(delcommafy(obj.businessManage.tenderFee))
            obj.businessManage.taxesFee = Number(delcommafy(obj.businessManage.taxesFee))
            obj.businessManage.actualStampTax = Number(
              delcommafy(obj.businessManage.actualStampTax)
            )
            obj.businessManage.actualAdditionalTax = Number(
              delcommafy(obj.businessManage.actualAdditionalTax)
            )
            obj.businessManage.contentCooperationRatio = Number(
              delcommafy(obj.businessManage.contentCooperationRatio)
            )
            // 筛选出修改过的字段
            const oldBusinessForm = this.$refs.Manage.oldBusinessForm
            let editArr = []
            Object.keys(oldBusinessForm).forEach(oldKey => {
              let old = oldBusinessForm[oldKey]
              if (typeof oldBusinessForm[oldKey] === 'string') {
                old = oldBusinessForm[oldKey].trim()
              }
              Object.keys(obj.businessManage).forEach(key => {
                let e = obj.businessManage[key]
                if (typeof obj.businessManage[key] === 'string') {
                  e = obj.businessManage[key].trim()
                }
                if (
                  oldKey === key &&
                  old !== e &&
                  !['principalUserName', 'suggestionPrincipalUserName'].includes(oldKey) &&
                  !['principalUserName', 'suggestionPrincipalUserName'].includes(e)
                ) {
                  editArr.push(key)
                }
              })
            })
            obj.businessManage.changeFieldList = editArr
            this.loading = true
            this.$api.businessManage
              .handOver(obj)
              .then(res => {
                this.loading = false
                this.showEditDialog = false
                this.$message.success('移交成功！')
                this.re()
              })
              .catch(err => {
                this.loading = false
                console.log(err)
              })
          })
          .catch(() => {})
      }
    },
    init() {
      this.loading = true
      if (this.options.id) {
        this.$api.businessManage
          .getBusinessManage(this.options.id)
          .then(res => {
            this.loading = false
            this.oldBusinessFileList = []
            let arr = []
            if (res.data.businessFileList.length > 0) {
              for (let i = 0; i < res.data.businessFileList.length; i++) {
                const e = res.data.businessFileList[i]
                e.attachmentName = e.fileName
                e.attachmentPath = e.filePath
                e.remark = e.remark ? e.remark : ''
                arr.push(e)
                this.oldBusinessFileList.push(e.id)
              }
            }
            this.file_Data = arr
            res.data.businessManage.list_s = this.options.list_s
            res.data.businessManage.role_type = this.options.role_type
            this.form = res.data.businessManage
            if (this.options.list_s) {
              this.optionInfo.list_s = 2
              this.optionInfo.list_check = false
            } else {
              this.optionInfo.list_s = 1
              this.optionInfo.list_check = true
            }
          })
          .catch(err => {
            this.loading = false
            console.log(err)
          })
      } else {
        this.file_Data = []
      }
    },
  },
}
</script>

<style scoped lang="scss">
// @import "@/styles/edit.scss";

@import '@/styles/config.scss';

.danwei {
  position: absolute;
  right: 2%;
}
</style>
